export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBC2xRy1A5jPze3wjlyVk8X13qwpd4pcCg",
    authDomain: "orlicollect.firebaseapp.com",
    databaseURL: "https://orlicollect-default-rtdb.firebaseio.com",
    projectId: "orlicollect",
    storageBucket: "orlicollect.appspot.com",
    messagingSenderId: "332074322082",
    appId: "1:332074322082:web:79f1a91928f2b89521effb",
    measurementId: "G-GTCKTB39HX"
  },
  adminEmail: 'support@sa-safeme.com', // you need to create new account in firebase auth manually,
  defaultCarSettings: {
    prices: {
      default: {
        vehicles: {
          suv: {
            base_fare: "1",
            base_km: "10",
            commission_type: "percentage",
            commission_value: "5",
            icon: "assets/img/suv.svg",
            map_icon: "assets/img/map-suv.png",
            name: "SUV",
            per_km: "1",
            seats: "4",
            tax: "5",
            type: "suv",
          }
        }
      }
    }
  },
  defaultSettings: {
    canDriverSignup: true,
    canRiderSignup: true,
    currency: "R",
    driverInitialRating: "5",
    driverPrivacyURL: "https://www.sa-safeme.com/terms",
    driverSignupBonus: "10",
    driverSupportURL: "https://www.sa-safeme.com/contact",
    driverTermsURL: "https://www.sa-safeme.com/terms",
    riderPrivacyURL: "https://www.sa-safeme.com/terms",
    riderSupportURL: "https://www.sa-safeme.com/contact",
    riderTermsURL: "https://www.sa-safeme.com/terms",
    sos: "911"
  }
};
